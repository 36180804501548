import { useEffect } from "react";
import { useFirebase } from "../../hooks/firebase/useFirebase";
import config from "../../config/global";

const AnalyticsTracker = () => {
  const {
    analytics: { track },
  } = useFirebase({});

  useEffect(() => {
    const eventName = config.ANALYTICS_WINDOW_EVENT_NAME;

    const listener = (event: any) => {
      track({
        name: event.name,
        props: event.detail,
      });
    };

    window.addEventListener(eventName, listener, false);

    return () => {
      window.removeEventListener(eventName, listener, false);
    };
  }, []);

  return <></>;
};

export default AnalyticsTracker;
